import React from "react";
import "animate.css";

function Newproduct() {
  const data = [
    {
      title:
        "UFED Touch 2 อุปกรณ์ถอดรหัสข้อมูลจากเครื่องโทรศัพท์มือถือ โปรแกรมถอดรหัสข้อมูลจากเครื่องโทรศัพท์มือถือ",
      imgPath: "/newproduct/1p.png",
    },
    {
      title:
        "Analytics Desktop โปรแกรมวิเคราะห์ความสัมพันธ์ของข้อมูลโทรศัพท์มือถือ ทำให้ข้อมูลดำเนินการได้ เปิดเผยหลักฐานเร็วขึ้น",
      imgPath: "/newproduct/2p.png",
    },
    {
      title: "COBWEB ระบบสืบค้นข้อมูลเว็บและข้อมูลสื่อสังคมออนไลน์",
      imgPath: "/newproduct/3p.png",
    },
    {
      title:
        "เซนเซอร์ตรวจจับความเคลื่อนไหวภาคพื้นดิน RDC2 ระบบการตรวจสอบและการจัดเก็บข้อมูลระยะไกล",
      imgPath: "/newproduct/4p.png",
    },
    {
      title:
        "UFED Cloud Analyzer เครื่องมือวิเคราะห์ UFED Cloud Analyzer ที่มีประสิทธิภาพของ Cellebrite ช่วยให้ผู้สืบสวน สามารถแยกข้อมูลและวิเคราะห์ข้อมูลบัญชีสื่อสังคมออนไลน์",
      imgPath: "/newproduct/5p.png",
    },
  ];
  return (
    <div className="w-full bg-[#f4f4f4] py-16 sm:py-8">
      <div
        className={`flex sm:px-4 flex-col items-center justify-center animate__animated animate__fadeInRight animate__slow`}
      >
        <p className="titlecontent pt-3">
          {"สินค้าอื่นๆ"}
          <span className="text-warning">.</span>
        </p>
      </div>
      <div className="grid grid-cols-5 md:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 gap-0 mt-4">
        {data.map((item, index) => {
          return (
            <div
              className="relative group overflow-hidden"
              key={`product${index}`}
            >
              <img
                src={item.imgPath}
                className="w-full h-full object-cover"
                alt={`product${index}`}
              />
              <div className="absolute top-[-100%] left-0 w-full h-full bg-black opacity-80 group-hover:top-0 transition-all duration-500 ease-in-out p-5 text-white text-xl">
                {item.title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Newproduct;
